<app-header></app-header>
<section class="section1 padding-header innerPageBanner">
    <div class="container-1440">
      <div class="head-text">
        <h1>Order Fulfillment</h1>
      </div>
    </div>
  </section>
  
  <section class="section2" id="aboutUs">
    <div class="container-1440">
      <div class="row">
        <div class="col-lg-6 my-auto">
          <div class="about-text about-textTn">
            <!-- <h1>Lorem Ipsum</h1> -->
            <p>
              We are a pioneer in order fulfillment services committed to developing the skills of order processing so that your clients will get their orders with accuracy, 
              promptness, and consideration. We have established ourselves as a dependable partner for companies trying to improve customer satisfaction and optimize 
              their operations with our dedication to quality and enthusiasm for decomposing the complexity of order processing.
            </p>
            <!-- <h1>Lorem Ipsum</h1> -->
            <p>
              We offer all-inclusive order fulfillment services to businesses that increase customer satisfaction, optimize operational effectiveness, 
              and promote company expansion. Our goal is to lead the revolution in how businesses view and manage order processing.
            </p>
            <a>
              <button class="btn-regular btn-sec2" [routerLink]="['/quote']">
                REQUEST A QUOTE
              </button>
            </a>
          </div>
        </div>
        <div class="col-lg-6 my-auto">
          <div class="sec2-image">
            <img src="assets/sec2.png" class="aboutus-img" />
          </div>
        </div>
      </div>
    </div>
  </section>


