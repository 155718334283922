<app-header></app-header>
<section class="section1 padding-header innerPageBanner">
  <div class="container-1440">
    <div class="head-text">
      <h1>Blogs</h1>
    </div>
  </div>
</section>

<section class="section2" id="aboutUs">
  <div class="container-1440">
    <div class="row blogList">
      <div class="col-lg-4">
        <div class="about-text about-textTn blogGrid">
          <div class="imgBlog">
            <img src="assets/blog1.png" class="aboutus-img" />
          </div>
          <div class="blogCont">
            <h4>Hiring Professional Packaging Design Services</h4>
            <p>Imagine entering a shop where the shelves are stacked high with merchandise. What draws your attention?
              Most likely, the packaging is to blame.</p>
            <a [routerLink]="['/hiring-professional-packaging-design-services']">
              <button class="btn-regular btn-sec2">
                Read More
              </button>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="about-text about-textTn blogGrid">
          <div class="imgBlog">
            <img src="assets/blog2.png" class="aboutus-img" />
          </div>
          <div class="blogCont">
            <h4>Importance Of Promotional Product Packages In Ecommerce</h4>
            <p>Promotional product packages are a great way to stay in front of consumers' minds in a busy environment,
              but it's getting harder to stand out as more businesses flood the market with their offerings.</p>
            <a [routerLink]="['/importance-of-promotional-product-packages-in-ecommerce']">
              <button class="btn-regular btn-sec2">
                Read More
              </button>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="about-text about-textTn blogGrid">
          <div class="imgBlog">
            <img src="assets/blog3.png" class="aboutus-img" />
          </div>
          <div class="blogCont">
            <h4>The Inventory Management Specialist And The System</h4>
            <p>Accurate Inventory management is essential for warehouses, manufacturing facilities, and retail
              establishments to sustain production, satisfy client requests, and keep an eye on available inventory.</p>
            <a [routerLink]="['/the-inventory-management-specialist-and-the-system']">
              <button class="btn-regular btn-sec2">
                Read More
              </button>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="about-text about-textTn blogGrid">
          <div class="imgBlog">
            <img src="assets/blog4.png" class="aboutus-img" />
          </div>
          <div class="blogCont">
            <h4>The Role of Warehousing And Logistics in E-commerce</h4>
            <p>In the dynamic world of international trade, efficient warehousing and logistics are overlooked icons
              that ensure that commodities reach customers without a hitch.</p>
            <a [routerLink]="['/the-role-of-warehousing-and-logistics-in-e-commerce']">
              <button class="btn-regular btn-sec2">
                Read More
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>