import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-label-printing-pasting',
  templateUrl: './label-printing-pasting.component.html',
  styleUrls: ['./label-printing-pasting.component.css']
})
export class LabelPrintingPastingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
