<app-header></app-header>
<section class="section1 padding-header innerPageBanner">
  <div class="container-1440">
    <div class="head-text">
      <h1>Inventory Management</h1>
    </div>
  </div>
</section>

<section class="section2" id="aboutUs">
  <div class="container-1440">
    <div class="row">
      <div class="col-lg-6 my-auto">
        <div class="about-text about-textTn">
          <!-- <h1>Lorem Ipsum</h1> -->
          <p>
            Our inventory management specialist provides a lot of expertise in inventory management and an awareness of
            the complexity and issues that organizations encounter.
            Our team is passionate about simplifying the complicated aspects of inventory control and is committed to
            excellence.
            We use this knowledge to create customized solutions for each customer, making sure that inventory is
            streamlined for accuracy and efficiency.
          </p>
          <!-- <h1>Lorem Ipsum</h1> -->
          <p>
            Our inventory management specialist provides extensive inventory management services that lower carrying
            costs, and increase operational efficiency.
            This is our clear and revolutionary objective. Our goal is to spearhead a new age of inventory control that
            promotes quality, sustainability, and development.
          </p>
          <a>
            <button class="btn-regular btn-sec2" [routerLink]="['/quote']">
              REQUEST A QUOTE
            </button>
          </a>
        </div>
      </div>
      <div class="col-lg-6 my-auto">
        <div class="sec2-image">
          <img src="assets/sec2.png" class="aboutus-img" />
        </div>
      </div>
    </div>
  </div>
</section>