<app-header></app-header>
<section class="section1 padding-header">
  <div class="container-1440">
    <div class="head-text">
      <h1>Full Sustainable</h1>
      <h3>Your storage Solutions</h3>
      <p>
        We are the leading cost-effective 24-hour manned warehousing and logistics hub providing storage solutions to our clients.
      </p>
      <div class="btns-head">
        <a>
          <button
            class="btn-regular btn-service"
            (click)="scrollFun('servicePrice')"
          >
            Our Services
          </button>
        </a>
        <a>
          <button class="btn-regular btn-about" (click)="scrollFun('aboutUs')">
            About Us
          </button>
        </a>
      </div>
    </div>
  </div>
</section>

<section class="section2" id="aboutUs">
  <div class="container-1440">
    <div class="row">
      <div data-aos="fade-right" class="col-lg-6 my-auto">
        <div class="about-text">
          <h1>About Us</h1>
          <p>
            Aim Warehousing is the leading 3PL warehouse and fulfillment center for e-commerce orders in Houston, Texas. 
            We offer affordable warehousing services and cost-saving global solutions for inventory storage, packaging, labeling, and shipping. 
            We can also manage your sales return and inventory with our professional sales team to help accelerate your sales and grow your brand.
          </p>
          <h1>OUR MISSION</h1>
          <p>
            We aim to provide warehouse storage facilities and develop working partnerships built on trust, efficiency, and transparency. 
            Our results are designed to exceed your customer's expectations and expand your brand with smart scaling growth solutions to maximize sales and profitability.
          </p>
          <a>
            <button class="btn-regular btn-sec2" [routerLink]="['/quote']">
              REQUEST A QUOTE
            </button>
          </a>
        </div>
      </div>
      <div data-aos="fade-left" class="col-lg-6 my-auto">
        <div class="sec2-image">
          <img src="assets/sec2.png" class="aboutus-img" />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section3">
  <div
    class="container-1440"
    data-aos="fade-right"
    data-aos-easing="ease-out-cubic"
    data-aos-duration="1000"
  >
    <div class="how-it-work">
      <h1>How it works</h1>
      <p>
        How Aim warehousing service goes from your online store to your customer’s door
      </p>
    </div>
    <div class="row">
      <div data-aos="flip-up" class="col-lg-4 col-md-6 my-3 mx-auto">
        <div class="card-works next">
          <img src="assets/connect.png" />
          <h4>1. Connect</h4>
          <p>
            Connect your store, import your products, then send us your
            inventory.
          </p>
        </div>
      </div>
      <div data-aos="flip-up" class="col-lg-4 col-md-6 my-3 mx-auto">
        <div class="card-works next">
          <img src="assets/warehouse.png" />
          <h4>2. Store</h4>
          <p>We store your inventory in our fulfillment center.</p>
        </div>
      </div>
      <div data-aos="flip-down" class="col-lg-4 col-md-6 my-3 mx-auto">
        <div class="card-works">
          <img src="assets/ship.png" />
          <h4>3. Ship</h4>
          <p>
            As soon as a customer places an order, we ship from our fulfillment
            center for you.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section4">
  <div data-aos="zoom-out-left" class="container-1440">
    <div class="row">
      <div class="col-lg-5">
        <div class="image-sec-4">
          <img src="assets/sec4.png" class="powerful-image" />
        </div>
      </div>
      <div class="col-lg-7">
        <div class="text-sec4">
          <h1>Powerful Network of Fulfillment Partners</h1>
          <p>
            Our inventory management specialist helps you determine the optimal placement across our growing fulfillment network to improve delivery speed and reduce shipping costs. 
            Scale your business efficiently by sending your growing inventory to our warehouses.
          </p>
          <div class="list-sec4">
            <ul>
              <li>Affordable 2-day shipping</li>
              <li>Easy-to-understand pricing</li>
              <li>Wide range of fulfillment services</li>
              <li>Transparency of performance for accountability</li>
              <li>Hands-off fulfillment so you can focus on everything else</li>
            </ul>
          </div>
        </div>
        <hr class="hr-sec4" />
        <div class="sponsors">
          <span><img src="assets/sponsors1.png" /></span>
          <span><img src="assets/sponsors2.png" /></span>
          <span><img src="assets/sponsors3.png" /></span>
          <span><img src="assets/sponsors4.png" /></span>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section5">
  <div class="sec5-text text-center">
    <h1>Aim Warehousing</h1>
    <p>
      Aim warehousing enables you to focus on service and growth while we maintain your inventory organized and your sales requests go all from our dependable and efficient warehouse. 
      We will pick, pack, and ship orders correctly to keep your customers happy. Accelerate your sales with Aim Warehousing.
    </p>
  </div>
  <div class="container-1440 pt-5">
    <div
      data-aos="fade-up-right"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="1000"
      class="row"
    >
      <div class="col-lg-7 my-auto">
        <div class="inventory-warehouse">
          <h1>Inventory Management & Sales:</h1>
          <p>
            Get control and manage your goods by UPS,fnsku labels, and ISBN product description. We make tracking simple and accessible with shipping tracking numbers available for review. 
            Our inventory management specialist works with our clients to help generate sales through strategic connections with shopping platforms and marketplaces.
          </p>
        </div>
      </div>
      <div class="col-lg-5 my-auto">
        <div class="inventory-warehouse">
          <img src="assets/inventory.png" class="inventry-img" />
        </div>
      </div>
    </div>
    <div
      data-aos="fade-left"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="1000"
      class="row pdbt-70 column-reverse"
    >
      <div class="col-lg-5 my-auto">
        <div class="inventory-warehouse">
          <img src="assets/warehousesec5.jpeg" class="offering-img" />
        </div>
      </div>
      <div class="col-lg-7 my-auto">
        <div class="inventory-warehouse">
          <h1>
            Aim Warehousing offers more than warehouse and storage services:
          </h1>
          <p>
            In addition to our warehousing and storage services, Aim Warehousing offers label printing and pasting. 
            Customize repacking services for pallets and boxes with bubble fillers, poly bags, Shrink wrap services, and promotional packaging.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section6" id="servicePrice">
  <div class="container-1440">
    <div class="pricing text-center">
      <h1>PRICING & COSTING</h1>
      <h5>AMAZON FBA PREP</h5>
    </div>
    <div class="row">
      <div class="col-lg-12 table-pricing">
        <div>
          <h3>&nbsp;</h3>
        </div>
        <div class="table-responsive table-bg">
          <table class="table table-1">
            <thead class="thead-table">
              <tr>
                <!-- <th scope="col">Services</th> -->
                <th scope="col">Pricing Model</th>
                <th scope="col">Cost</th>
              </tr>
            </thead>
            <tbody class="thead-body">
              <!-- <tr *ngFor="let service of ServiceData">
                                <td *ngIf="service.type == 'Normal Service'">{{service.services}}</td>
                                <td *ngIf="service.type == 'Normal Service'"><p>{{service.lb}}</p><p>{{service.dimension}}</p></td>
                                <td *ngIf="service.type == 'Normal Service'">{{service.cost}}</td>
                            </tr> -->
              <tr>
                <td>Receiving and visual inspection/ item</td>
                <td>$0.15</td>
              </tr>
              <tr>
                <td>Damage pictures</td>
                <td>$0.5</td>
              </tr>
              <tr>
                <td>FNSKU Label single pack</td>
                <td>$0.35</td>
              </tr>
              <tr>
                <td>FNSKU label + Pack of 2 label</td>
                <td>$0.65</td>
              </tr>
              <tr>
                <td>FNSKU label + Pack of 3 label</td>
                <td>$0.8</td>
              </tr>
              <tr>
                <td>FNSKU label + Pack of 4 label or above</td>
                <td>$1</td>
              </tr>
              <tr>
                <td>FBA label</td>
                <td>$0.5 Each</td>
              </tr>
              <tr>
                <td>Shipping label</td>
                <td>$0.5 each</td>
              </tr>
              <tr>
                <td>Poly bag</td>
                <td>$0.5-$2.25 each</td>
              </tr>
              <tr>
                <td>Bubble wrap</td>
                <td>$0.4 each</td>
              </tr>
              <tr>
                <td>Shrink wrap</td>
                <td>$0.5 each</td>
              </tr>
              <tr>
                <td>New Box</td>
                <td>$2.5-$6</td>
              </tr>
              <tr>
                <td>Label remover</td>
                <td>$0.2</td>
              </tr>
              <tr>
                <td>Fragile sticker</td>
                <td>$0.2</td>
              </tr>
              <tr>
                <td>Box Handling</td>
                <td>$3-10</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="col-lg-5 table-pricing border-change-l">
        <h3>Pallet</h3>
        <div class="table-responsive table-bg">
          <table class="table table-1 table-2">
            <thead class="thead-table">
              <tr>
                <th scope="col">Services</th>
                <th scope="col">Cost</th>
              </tr>
            </thead>
            <tbody class="thead-body">
              <tr *ngFor="let pallet of ServiceData">
                <td *ngIf="pallet.type == 'Pallet'">{{ pallet.services }}</td>
                <td *ngIf="pallet.type == 'Pallet'">{{ pallet.cost }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->
    </div>
    <div class="sec6-last-para">
      <p>
        We will maintain your inventory daily. Other services will be charged
        separately. The use of the forklift is included in the prices listed
        below.
      </p>
    </div>
    <div class="storage">
      <h4 class="mb-0">Storage</h4>
      <p class="mb-2 text-center">
        7 days Free with in a limited weight and dimension.
      </p>
      <div class="row">
        <div class="col-lg-4 table-pricing">
          <h3>Bin</h3>
          <div class="table-responsive table-bg">
            <table class="table table-1">
              <tbody class="thead-body storage-table">
                <tr
                  *ngFor="let bin of storageData"
                  [ngClass]="{ hideTr: bin.type != 'Bin' }"
                >
                  <td *ngIf="bin.type == 'Bin'">{{ bin.dimension }}</td>
                  <td *ngIf="bin.type == 'Bin'">{{ bin.cost }}</td>
                </tr>
                <tr>
                  <td>12"x10"x8"</td>
                  <td>$3 /month</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-lg-4 table-pricing border-change-l-r">
          <h3>Shelf</h3>
          <div class="table-responsive table-bg">
            <table class="table table-1">
              <tbody class="thead-body storage-table">
                <tr
                  *ngFor="let shelf of storageData"
                  [ngClass]="{ hideTr: shelf.type != 'Shelf' }"
                >
                  <td *ngIf="shelf.type == 'Shelf'">{{ shelf.dimension }}</td>
                  <td *ngIf="shelf.type == 'Shelf'">{{ shelf.cost }}</td>
                </tr>
                <tr>
                  <td>12"x18"x36"</td>
                  <td>$8.00/month</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-lg-4 table-pricing">
          <h3>Pallet</h3>
          <div class="table-responsive table-bg">
            <table class="table table-1">
              <tbody class="thead-body storage-table">
                <tr
                  *ngFor="let pallet of storageData"
                  [ngClass]="{ hideTr: pallet.type != 'Pallet' }"
                >
                  <td *ngIf="pallet.type == 'Pallet'">
                    {{ pallet.dimension }}
                  </td>
                  <td *ngIf="pallet.type == 'Pallet'">{{ pallet.cost }}</td>
                </tr>
                <tr>
                  <td>Outside Building</td>
                  <td>$20/month</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-loading
    [show]="loading"
    [config]="{ backdropBorderRadius: '3px' }"
  ></ngx-loading>
</section>

<!-- <section class="section7">
  <div class="become-affiliate">
    <h1>Become Our AFFILIATES</h1>
  </div>
  <div class="">
    <div class="row">
      <div class="col-xl-6 col-lg-12 px-0">
        <div
          class="contact"
          data-aos="fade-up-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
        >
          <img src="assets/joinus.png" class="affilated-img" />
          <div class="joinus-text">
            <h2>JOIN US</h2>
            <p>
              You get paid for every merchant you refer. Bring your clients,
              grow your business, and we’ll facilitate the warehousing services
            </p>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-12 px-0 input-borders">
        <div
          class="form-contact max-width-560"
          data-aos="fade-up-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
        >
          <h3>Want to Join Us?</h3>
          <h3>Join our affiliate program and grow your revenue.</h3>
          <div class="form-contact">
            <div class="row">
              <div class="col-lg-12 px-2">
                <label>Your full name <span>*</span></label
                ><br />
                <input
                  type="text"
                  name=""
                  placeholder=""
                  [(ngModel)]="userDetails.full_name"
                />
              </div>
              <div class="col-lg-6 px-2">
                <label>email <span>*</span></label
                ><br />
                <input
                  type="text"
                  name=""
                  placeholder=""
                  [(ngModel)]="userDetails.email"
                />
              </div>
              <div class="col-lg-6 px-2">
                <label>phone number <span>*</span></label
                ><br />
                <input
                  type="text"
                  name=""
                  placeholder=""
                  [(ngModel)]="userDetails.ph_number"
                />
              </div>
              <div class="col-lg-6 px-2">
                <label>ID NUMBER <span>*</span></label
                ><br />
                <input
                  type="text"
                  name=""
                  placeholder=""
                  [(ngModel)]="userDetails.id_number"
                />
              </div>
              <div class="col-lg-6 px-2">
                <label>your experience in years <span>*</span></label
                ><br />
                <select
                  name="experianceYear"
                  [(ngModel)]="userDetails.experience"
                >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
              <div class="col-lg-12 px-2">
                <label>Additional Message</label><br />
                <textarea [(ngModel)]="userDetails.question"></textarea>
              </div>
              <div class="col-lg-12 px-2">
                <div class="btn-form">
                  <div class="upload-btn">
                    <label class="upload-label" for="filetn">
                      <input
                        type="file"
                        class="custom-file-input-form"
                        id="filetn"
                        (change)="fileChange($event)"
                        accept=".pdf,.doc,.docx"
                      />
                      <div class="uploadbox">
                        <p>Attach File</p>
                        <i class="fa fa-paperclip ml-1"></i>
                        <div class="uploadboxfile">
                          {{ file.length }} File Attach
                        </div>
                      </div>
                    </label>
                  </div>
                  <div>
                    <a>
                      <button
                        class="btn-regular btn-upload"
                        (click)="onUpload()"
                      >
                        Upload
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="section8">
  <div class="container-1440">
    <div class="text-testiomonial">
      <h1>Testimonials</h1>
    </div>
    <swiper
      [autoplay]="{ delay: 2500, disableOnInteraction: false }"
      class="mySwiper"
      [navigation]="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }"
    >
      <ng-template swiperSlide>
        <div class="testimonial-carousel">
          <div class="testimonial">
            <div class="profile-testimonial">
              <div class="info-test text-center">
                <p>
                  The complete logistics solutions provided by AIM Warehousing
                  have significantly streamlined our operations. Their
                  technology offers a comprehensive approach to inventory
                  management, order processing, and shipping, saving time and
                  reducing errors. Choosing AIM Warehousing was one of the best
                  moves ever made for our company.
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="testimonial-carousel">
          <div class="testimonial">
            <div class="profile-testimonial">
              <div class="info-test text-center">
                <p>
                  AIM Warehousing has played a pivotal role in facilitating the
                  expansion of our startup.Their adaptable solutions have
                  empowered us to diversify our range of products without the
                  burden of storage or fulfillment concerns. The customer
                  support is proactive and prompt, guaranteeing a seamless
                  experience consistently. The services provided by AIM
                  Warehousing have emboldened us to set ambitious goals and
                  strive for excellence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="testimonial-carousel">
          <div class="testimonial">
            <div class="profile-testimonial">
              <div class="info-test text-center">
                <p>
                  I can't say enough about how pleased I am with AIM
                  Warehousing's services. As an e-commerce business owner, I
                  place a high value on accurate inventory management and timely
                  order fulfillment. AIM Warehousing has outperformed my
                  expectations by offering seamless connection, real-time
                  updates, and lightning-fast shipping. My consumers are more
                  satisfied than ever, and I am as well.
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>

    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>

    <!-- <swiper
      [autoplay]="{ delay: 2500, disableOnInteraction: false }"
      class="mySwiper"
    >
      <ng-template swiperSlide *ngFor="let x of slideData">
        <div class="testimonial-carousel">
          <div class="testimonial">
            <h4>&ldquo; {{ x.text }}&rdquo;</h4>
            <div class="profile-testimonial">
              <div class="avatar-img">
                <img src="{{ baseUrl + x.image }}" />
              </div>
              <div class="info-test">
                <p>{{ x.name }}</p>
                <p *ngIf="x.c_name != 'undefined'">{{ x.c_name }}</p>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </swiper> -->
  </div>
</section>
