<app-header></app-header>

<div class="not-found">
    <h1>404 Error</h1>
    <h1 class="my-5">Page Not Found</h1>
    <a [routerLink]="['/']">
        <button class="btn-regular btn-sec2">
            Back To Home
        </button>
    </a>
</div>