import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hiring-professional-packaging-design-services',
  templateUrl: './hiring-professional-packaging-design-services.component.html',
  styleUrls: ['./hiring-professional-packaging-design-services.component.css']
})
export class HiringProfessionalPackagingDesignServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
