import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-the-role-of-warehousing-and-logistics-in-e-commerce',
  templateUrl: './the-role-of-warehousing-and-logistics-in-e-commerce.component.html',
  styleUrls: ['./the-role-of-warehousing-and-logistics-in-e-commerce.component.css']
})
export class TheRoleOfWarehousingAndLogisticsInECommerceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
