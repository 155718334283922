<app-header></app-header>
<section class="section1 padding-header innerPageBanner">
    <div class="container-1440">
      <div class="head-text">
        <h1>Sales Returns Management</h1>
      </div>
    </div>
  </section>
  
  <section class="section2" id="aboutUs">
    <div class="container-1440">
      <div class="row">
        <div class="col-lg-6 my-auto">
          <div class="about-text about-textTn">
            <!-- <h1>Lorem Ipsum</h1> -->
            <p>
              It's necessary to handle dissatisfied clients, but you should also take advantage of the return process to increase long-term growth, operational efficiency, and customer loyalty. 
              To help businesses better manage and minimize the issues associated with returns while boosting customer happiness, we are committed to streamlining and optimizing the intricate procedures around product returns. 
            </p>
            <!-- <h1>Lorem Ipsum</h1> -->
            <p>
              We provide cutting-edge sales return management solutions to companies that pay off. In the future, sales return management will 
              be a strategic advantage for companies rather than a source of distress.
            </p>
            <a>
              <button class="btn-regular btn-sec2" [routerLink]="['/quote']">
                REQUEST A QUOTE
              </button>
            </a>
          </div>
        </div>
        <div class="col-lg-6 my-auto">
          <div class="sec2-image">
            <img src="assets/sec2.png" class="aboutus-img" />
          </div>
        </div>
      </div>
    </div>
  </section>



