<footer>
  <div class="container-1440">
    <div class="row">
      <div class="col-xl-4 col-lg-12">
        <div class="footer-col-1">
          <img src="assets/logo.png" />
          <p>AiM WAREHOUSE #</p>
          <a href="tel:8329293513">832-929-3513</a>
          <!-- <p>TOLL FREE #</p> -->
          <!-- <a href="tel:8669977670">866-997-7670</a> -->
          <p>16635 HOLLISTER STREET , BUILDING C, HOUSTON, TX, 77066</p>
        </div>
      </div>
      <!-- <div class="col-xl-3 col-lg-4 col-md-6 mx-auto">
                <div class="footer-col-2">
                    <h3>Useful Links</h3>
                    <ul>
                        <a href="#">
                            <li>HOME</li>
                        </a>
                        <a href="#">
                            <li>FIRM OVERVIEW</li>
                        </a>
                        <a href="#">
                            <li>ATTORNEY PROFILES</li>
                        </a>
                        <a href="#">
                            <li>CONTACT US</li>
                        </a>
                        <a href="#">
                            <li>PAYMENT PORTAL</li>
                        </a>
                    </ul>
                </div>
            </div> -->
      <div class="col-xl-3 col-lg-4 col-md-6 mx-auto">
        <div class="footer-col-4">
          <h3>Services</h3>
          <ul>
            <li>
              <a [routerLink]="['/label-printing-pasting']">
                <p>Label Printing & Pasting</p>
              </a>
            </li>
            <li>
              <a [routerLink]="['/sales-returns-management']">
                <p>Sales Returns Management</p>
              </a>
            </li>
            <li>
              <a [routerLink]="['/promotional-packaging']">
                <p>Promotional Packaging</p>
              </a>
            </li>
            <li>
              <a [routerLink]="['/package-designing']">
                <p>Package Designing</p>
              </a>
            </li>
            <li>
              <a [routerLink]="['/order-fulfillment']">
                <p>Order Fulfillment </p>
              </a>
            </li>
            <li>
              <a [routerLink]="['/inventory-management']">
                <p>Inventory Management</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 mx-auto">
        <div class="footer-col-3">
          <h3>Useful Links</h3>
          <ul>
            <li>
              <a href="https://www.facebook.com/AimWarehousing-219630596691617/" target="_blank">
                <p>Facebook</p>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/aimwarehousing/" target="_blank">
                <p>Instagram</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright">
    <p>©{{ footerDate | date : "yyyy" }} AIM WAREHOUSING LLC.</p>
  </div>
</footer>