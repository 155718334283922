<app-header></app-header>
<section class="section1 padding-header innerPageBanner">
    <div class="container-1440">
      <div class="head-text">
        <h1>Package Designing</h1>
      </div>
    </div>
  </section>
  
  <section class="section2" id="aboutUs">
    <div class="container-1440">
      <div class="row">
        <div class="col-lg-6 my-auto">
          <div class="about-text about-textTn">
            <!-- <h1>Lorem Ipsum</h1> -->
            <p>
              Our group of enthusiastic designers and visionaries is committed to transforming your goods into eye-catching masterpieces that command attention from consumers. 
              We've established ourselves as a reliable packaging design service for companies looking to design packaging that not only captivates but also protects thanks 
              to our extensive expertise and profound understanding of the power of packaging.
            </p>
            <!-- <h1>Lorem Ipsum</h1> -->
            <p>
              Our goal is to provide companies with outstanding packaging design services that improve their brand, increase consumer attractiveness, and increase their competitiveness in the market. 
              Aim Warehousing is available to assist you in converting your product packaging into an effective branding instrument. 
            </p>
            <a>
              <button class="btn-regular btn-sec2" [routerLink]="['/quote']">
                REQUEST A QUOTE
              </button>
            </a>
          </div>
        </div>
        <div class="col-lg-6 my-auto">
          <div class="sec2-image">
            <img src="assets/sec2.png" class="aboutus-img" />
          </div>
        </div>
      </div>
    </div>
  </section>



