<app-header></app-header>
<section class="section7 padding-header">
    <div class="become-affiliate">
    </div>
    <div class="">
        <div class="row">
            <div class="col-xl-6 col-lg-12 px-0 mt-4 mb-4">
                <div class="contact">
                    <img src="assets/contact.jpg" class="affilated-img">
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 px-0 for-shadow mt-4 mb-4">
                <div class="form-contact  max-width-560">
                    <h1 *ngIf="router.url == '/contact'">Contact us</h1>
                    <h1 *ngIf="router.url == '/quote'">Get a Quote</h1>
                    <div class="form-contact">
                        <div class="row">
                            <div class="col-lg-6 px-2">
                                <label>Name <span>*</span></label><br>
                                <input type="text" name="name" placeholder="Name" [(ngModel)]="userInfo.name">
                            </div>
                            <div class="col-lg-6 px-2">
                                <label>Email <span>*</span></label><br>
                                <input type="text" name="email" placeholder="Email" [(ngModel)]="userInfo.email">
                            </div>
                            <div class="col-lg-6 px-2">
                                <label>phone number <span>*</span></label><br>
                                <input type="text" name="number" placeholder="number" [(ngModel)]="userInfo.phone">
                            </div>
                            <div class="col-lg-6 px-2">
                                <label>Company</label><br>
                                <input type="text" name="company" placeholder="company" [(ngModel)]="userInfo.company">
                            </div>
                            <div class="col-lg-12 px-2">
                                <div class="btn-form">
                                    <div>
                                        <a>
                                            <button class="btn-regular btn-upload" [routerLink]="['/']">Back</button>
                                            <button class="btn-regular btn-upload" (click)="onSubmit()">Submit</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p style="font-size: 14px;">A fulfillment expert will get back to you shortly. Once you’ve submitted the information above:</p>

                        <p style="font-size: 14px;">AimWarehousing Business Development Representative will contact you We’ll ask for order information to determine the right plan for your business. You can be shipping in as little as 6 days!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>