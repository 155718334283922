<app-header></app-header>
<section class="section1 padding-header innerPageBanner">
    <div class="container-1440">
      <div class="head-text">
        <h1>Label Printing Pasting</h1>
      </div>
    </div>
  </section>
  
  <section class="section2" id="aboutUs">
    <div class="container-1440">
      <div class="row">
        <div class="col-lg-6 my-auto">
          <div class="about-text about-textTn">
            <!-- <h1>    Lorem Ipsum</h1> -->
            <p>
              Labels are more than simply stickers on goods; they represent your brand's voice, conveying important information and making a lasting impact on customers. 
              Since our founding, we have become a dependable partner for companies looking for superior labeling solutions because of our passion for perfection and dedication to going above and beyond for our clients.
            </p>
            <!-- <h1>Lorem Ipsum</h1> -->
            <p>
              Aim warehousing can help you if you're looking for a partner to help you advance your label printing and pasting requirements. 
              Our goal is to lead the labeling business and establish the highest standards for customer service, quality, and customization. 
            </p>
            <a>
              <button class="btn-regular btn-sec2" [routerLink]="['/quote']">
                REQUEST A QUOTE
              </button>
            </a>
          </div>
        </div>
        <div class="col-lg-6 my-auto">
          <div class="sec2-image">
            <img src="assets/sec2.png" class="aboutus-img" />
          </div>
        </div>
      </div>
    </div>
  </section>

