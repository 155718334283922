<app-header></app-header>
<section class="section1 padding-header innerPageBanner">
    <div class="container-1440">
      <div class="head-text">
        <h1>Promotional Packaging</h1>
      </div>
    </div>
  </section>
  
  <section class="section2" id="aboutUs">
    <div class="container-1440">
      <div class="row">
        <div class="col-lg-6 my-auto">
          <div class="about-text about-textTn">
            <!-- <h1>Lorem Ipsum</h1> -->
            <p>
              Promotional Packaging services can make your brand unforgettable, we are enthusiastic about the art and science of packaging, with a focus on developing aesthetically attractive, 
              useful, and captivating promotional packaging solutions that captivate your target market. We have devoted our time to mastering the art of promotional 
              product packages so that your goods will stick out and your company will be remembered.
            </p>
            <!-- <h1>Lorem Ipsum</h1> -->
            <p>
              Our goal is to provide businesses with distinctive promotional packaging that strengthens their brand and increases the allure of their products. 
              Our goal is to lead the packaging industry by providing innovative and practical design and production solutions.
            </p>
            <a>
              <button class="btn-regular btn-sec2" [routerLink]="['/quote']">
                REQUEST A QUOTE
              </button>
            </a>
          </div>
        </div>
        <div class="col-lg-6 my-auto">
          <div class="sec2-image">
            <img src="assets/sec2.png" class="aboutus-img" />
          </div>
        </div>
      </div>
    </div>
  </section>



